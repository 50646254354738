export default [
  {
    key: 'id',
    label: 'Title',
    type: 'individual',
    similarityWeight: 1,
    selectable: false,
    unit: ''
  },
  {
    key: 'ruler_name_lat',
    label: 'Ruler',
    type: 'discrete',
    similarityWeight: 0.1,
    selectable: true,
    unit: ''
  },
  {
    key: 'period_lat',
    label: 'Period',
    type: 'discrete',
    similarityWeight: 0.1,
    selectable: true,
    unit: ''
  },
  {
    key: 'town_lat',
    label: 'Town',
    type: 'discrete',
    similarityWeight: 0.5,
    selectable: true,
    unit: ''
  },
  {
    key: 'country_lat',
    label: 'Country',
    type: 'discrete',
    similarityWeight: 0.1,
    selectable: true,
    unit: ''
  },
  {
    key: 'material',
    label: 'Material',
    type: 'discrete',
    similarityWeight: 1,
    selectable: true,
    unit: ''
  },
  {
    key: 'box',
    label: 'Storage Box',
    type: 'discrete',
    similarityWeight: 0.1,
    selectable: true,
    unit: ''
  },
  {
    key: 'weight',
    label: 'Weight',
    type: 'continuous',
    similarityWeight: 0.8,
    grouping: 10,
    selectable: true,
    unit: 'g'
  },
  {
    key: 'diameter',
    label: 'Diameter',
    type: 'continuous',
    similarityWeight: 0.1,
    grouping: 5,
    selectable: true,
    unit: 'mm'
  },
  {
    key: 'date_earliest',
    label: 'Date',
    type: 'continuous',
    similarityWeight: 0.6,
    grouping: 100,
    selectable: true,
    unit: '',
    labelGenerator: (value) => (
      {
        unit: value === 0 ? '' : (value < 0 ? 'BC' : 'AD'),
        value: value < 0 ? Math.abs(value) : value,
        modifiers: value === 0 ? ['highlight'] : []
      }
    )
  },
  {
    key: 'date_latest',
    label: 'Last Date',
    type: 'continuous',
    similarityWeight: 0.1,
    grouping: 100,
    selectable: false,
    unit: '',
    labelGenerator: (value) => (
      {
        unit: value === 0 ? '' : (value < 0 ? 'BC' : 'AD'),
        value: value < 0 ? Math.abs(value) : value,
        modifiers: value === 0 ? ['highlight'] : []
      }
    )
  }
]