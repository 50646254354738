import React from 'react';
import stateStore from 'app/stateStore';

class Intro extends React.Component {
  handleClose() {
    stateStore.set({showIntro: false, showHints: true});
  }
  handleShowInfo() {
    stateStore.set({showIntro: false, showInfo: true});
  }
  render() {
    const {transform} = this.props;
    return (
      <div className="popup">
        <div className="popup__inner">
          <div className="popup__header">
            <h1 className="popup__title">Coins</h1>
            <h2 className="popup__subtitle">Qatar Museums</h2>
          </div>
          <div className="popup__main">
            <p className="popup__copy">Explore a selection of coins belonging to one of the largest Islamic coin collections in the world! Every coin has its own history. This tool gives you the chance to explore these coins and sort them through different layouts and filters.</p>
          </div>
          <div className="popup__footer">
            <button onClick={this.handleClose} className="popup__btn btn btn--big btn--primary">Start</button>
            {/* <button onClick={this.handleShowInfo} className="popup__btn btn btn--big btn--secondary">More info</button> */}
            {/*<p className="popup__small">(This tool is built for the latest versions of all major browsers)</p>*/}
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;