import React from 'react';
import coinProperties from 'constants/coinProperties';
import {createLabelData} from 'utility';
import stateStore from 'app/stateStore';
import _find from 'lodash/find';

const detailPath = 'data/coins-256h';

class CoinInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
    };
  }

  handleClickCoin() {
    // stateStore.set({'coinFullscreen': true});
  }

  handleFlipCoin(val) {
    stateStore.set({coinFlipped: !stateStore.get().coinFlipped});
  }

  handleDeselectCoin() {
    stateStore.set({selectedCoin: null});
  }

  createProperty(coin, prop, i) {
    if(!prop.selectable) return;
    const {onLabelClick, coinFilters} = this.props;
    const rawValue = coin.data[prop.key];
    const labelData = createLabelData(prop, rawValue);
    const onClick = prop.type === 'discrete' ? onLabelClick.bind(null, [{key: prop.key, value: rawValue}]) : undefined;
    const isSelected = _find(coinFilters, {key: prop.key}) !== undefined;
    let valueClassName = 'coin-info__prop-value';
    valueClassName += prop.type === 'discrete' ? ' coin-info__prop-value--filterable' : '';
    valueClassName += isSelected ? ' is-selected' : '';

    // join years
    if(prop.key === 'date_latest') return;
    if(prop.key === 'date_earliest') {
      const dateLatestProp = _find(coinProperties, {key: 'date_latest'});
      const latestLabelData = createLabelData(dateLatestProp, coin.data.date_latest);
      labelData.value = `${labelData.value} - ${latestLabelData.value}`
    }

    return (
      <div key={i} className='coin-info__prop'>
        <div 
          onClick={onClick}
          title={`${labelData.value} ${labelData.unit}`}
          className={valueClassName}><i className={`coin-info__prop-icon icon-${prop.key}`}></i>{labelData.value} <span className="coin-info__prop-unit">{labelData.unit}</span></div>
      </div>
    )
  }

  render() {
    const {coin, transform} = this.props;
    const compact = transform.k < .6;
    let className = 'coin-info';
    className += compact ? ' coin-info--compact' : '';

    const { coinFlipped } = stateStore.get();

    return (
      <div 
        className={className}
        style={{
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
        }}
      >
        <div className={`coin-info__header ${this.state.hovered ? 'hovered' : ''} ${coinFlipped ? 'flipped' : ''}`}>
          <div className='coin-info__img' onClick={() => this.handleClickCoin()}>
            <img className="coin-info__front" src={`${detailPath}/${coin.data.id}_m01.png`} alt='Coin Detail Front' />
            {(coin.data.backside === 1) && <img className="coin-info__back" src={`${detailPath}/${coin.data.id}_m02.png`} alt='Coin Detail Back' />}
          </div>
          {(coin.data.backside === 1) && <button 
            title="Flip Coin"
            aria-label="Flip Coin"
            onClick={() => this.handleFlipCoin()}
            onMouseEnter={() => this.setState({hovered: true})}
            onMouseLeave={() => this.setState({hovered: false})}
            className='coin-info__btn'
          >
            <img className="coin-info__btn-front" src={`${detailPath}/${coin.data.id}_m01.png`} alt='Coin Front' />
            <img className="coin-info__btn-back" src={`${detailPath}/${coin.data.id}_m02.png`} alt='Coin Back' />
          </button>}
          <div className='coin-info__title'>{coin.data.id}</div>
        </div>
        {!compact && <div className='coin-info__props'>
          {coinProperties.map(this.createProperty.bind(this, coin))}
        </div>}
        
        <button onClick={this.handleDeselectCoin}
          style={{
            position: 'absolute', 
            right: '0', 
            top: '0', 
            width: '2rem', 
            height: '2rem',
            border: 'none',
            borderRadius: '1rem',
            backgroundColor: 'transparent',
            display: 'grid',
            placeContent: 'center',
            cursor: 'pointer',
            color: '#FACF8F',
          }}>
          <span className="icon-cross" style={{fontSize: '2rem'}}></span>
        </button>
      </div>
    );
  }
}

export default CoinInfo;